<template>
  <section id="hero">
    <v-sheet
      dark
      tile
    >
      <v-carousel
        :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
        :gradient="gradient"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-img
            :src="item.src"
            :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
            :gradient="gradient"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </v-row>
            </template>
            <v-container
              light
              fill-height
              fluid
              :px-12="$vuetify.breakpoint.mdAndUp"
            >
              <v-row
                align="center"
                justify="start"
              >
                <v-slide-x-reverse-transition appear>
                  <v-col
                    cols="12"
                    class="pl-12"
                    md="5"
                  >
                    <div>
                      <h1
                        :class="[
                          $vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'
                        ]"
                        class="font-weight-bold"
                        v-text="item.name"
                      />

                      <v-divider class="mb-3" />

                      <div class="subheading mb-4">
                        {{ item.text }}
                      </div>

                      <v-btn
                        :href="item.to"
                        color="white"
                        justify="space-around"
                        outlined
                        tile
                      >
                        Explore
                        <v-icon right>
                          {{ svgCompass }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-slide-x-reverse-transition>
              </v-row>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
    <experiences />
  </section>
</template>

<style scoped>
#scroll_more {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
</style>

<script>
  import { mdiGlassWine, mdiCompass } from '@mdi/js'

  export default {
    name: 'HomeHero',
    components: {
      Experiences: () => import('@/components/Experiences.vue')
    },
    props: {
      gradient: {
        type: String,
        default: 'to top, rgba(0,0,0,0), rgba(0,0,0,0)'
      }
    },
    data: () => ({
      svgWine: mdiGlassWine,
      svgCompass: mdiCompass,

      items: [
        {
          src: require('@/assets/Main/flowery.webp'),
          name: 'History',
          text:
            'Read about the history of Twin Streams and this magnificent area. ',
          to: '/history'
        },

        {
          src: require('@/assets/Main/walks/walk.webp'),
          name: 'Cycling, Hiking and walking paths',
          text: 'A haven for exploration',
          to: '/walks'
        },
        {
          src: require('@/assets/Main/birding/wal (2).webp'),
          name: 'Bird Watching',
          text: 'View some of the finest birding Southern Africa has to offer',
          to: '/birding'
        }
      ]
    })
  }
</script>
